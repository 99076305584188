export const TRANSLATION_CONSTANTS = {
  // General
  empowerGPT: 'EmpowerGPT',
  knowledgeBaseChat: 'Knowledge Base Chat',
  enterprise: 'Enterprise',
  worldKnowledge: 'World Knowledge',
  generalChat: 'General Chat',
  languageSwitcher: 'Select Language',
  logout: 'Logout',
  profile: 'Profile',
  uploadImport: 'Upload / Import',
  documents: 'Documents',
  conversations: 'Conversations done',
  valuableInsights: 'Quickly extract valuable insights',
  uploadYourDocuments: 'Upload documents',
  clearFilters: 'Reset',
  resetPassword: 'Change Password',
  userGroups: 'User Groups',
  userGroupName: 'User group name',
  accountType: 'Account Type',
  email: 'Email',
  startChat: 'Start Chat',
  addToChat: 'Add to chat',
  createdWithTime: 'Created {{time}} ago',
  modifiedWithTime: 'Modified {{time}} ago',
  syncedWithTime: 'Synced {{time}} ago',
  rolesAndPermissions: 'Roles And Permissions',
  selectItemsToDelete: 'Select Items to delete',
  selectItemsToTag: 'Select items to tag',
  selectItemsToMove: 'Select items to move',
  noPermissionToDelete: 'Do not have permission to delete',
  noPermissionToView: 'Do not have permission to view',
  noPermissionToMoveHere: 'Do not have permission to move here',
  noPermissionToDownload: 'Do not have permission to download',
  tagDisabledForWorkspaceChat:
    'Tags functionality is not available for workspace chats',
  typeToSearch: 'Type to search',
  searching: 'Searching...',
  usage: 'Usage',
  disclaimerEgpt:
    'AI Model can make mistakes, please consider verifying with the provided citations.',
  chatGPT: 'ChatGPT',
  reprocess: 'Reprocess',
  selectItemsToReprocess: 'Select items to reprocess',
  fileProcessing: 'File Processing',
  enterpriseKnowledgeChat: 'Enterprise Knowledge Chat',
  worldKnowledgeChat: 'World Knowledge Chat',
  ok: 'OK',
  goBack: 'Go Back',
  settings: 'Settings',
  version: 'Version',
  llmsSettingsHeading: 'AI Models Available in Chat',
  selectModels: 'Select Models',
  modelsWarning: 'Please select at least one model.',
  configure: 'Configure',
  tenantKnowledgeBase: '{{tenant}} knowledge',
  selected: 'Selected',
  noInternet:
    'No internet connection. Please check your internet and try again.',
  loading: 'Loading',
  addMore: 'Add more',

  //Filters
  search: 'Search',
  accountTypeSelect: 'Select Account Type',
  noOptions: 'No options',
  filters: 'Filters',
  revokeAccess: 'Revoke access',

  // Documents
  uploadDocs: 'Upload Documents',
  tagDocuments: 'Tag Documents',
  allDocuments: 'All documents',
  docName: 'Document name',
  uploadedBy: 'Uploaded By',
  modifiedBy: 'Modified By',
  syncedBy: 'Synced By',
  syncedOn: 'Synced On',
  syncing: 'Currently Syncing...',
  syncingInGridView: 'Please wait while the folder is being synced...',
  syncingFailed: 'Sync attempt failed',
  uploadedOn: 'Uploaded On',
  modifiedOn: 'Modified On',
  actions: 'Actions',
  view: 'View',
  share: 'Share',
  sync: 'Sync',
  sharedWith: 'Shared with',
  invite: 'Invite',
  details: 'AI Overview',
  documentType: 'Document Type',
  size: 'Size',
  length: 'Length',
  description: 'Description',
  rowsPerPage: 'Rows per page',
  uploadTimeAndDateWithUser: 'Uploaded {{time}} by {{user}}',
  download: 'Download',
  uploadProgress: 'Upload Progress',
  document: 'document',
  docsDropMessage: 'Drop the documents to start uploading',
  uploadDocFailed: `Failed to upload documents.`,
  uploadDocsFailed: `Failed to upload document.`,
  uploadMessage: `You have uploaded `,
  dropDocsMessage1: `Drop the documents to start uploading`,
  dropDocsMessage2: 'Drag & drop documents here',
  dropDocsRestriction: 'You cannot upload these document types.',
  unauthorizedFilesError: "You don't have permission to upload files/folders.",
  dropDocsPartialRestriction: 'Some invalid document types were rejected.',
  failedFetchingDocuments: 'Failed to fetch documents.',
  uploadDocsSuccess: '{{count}} document(s) uploaded successfully.',
  uploadStatusBoxMessage:
    '{{count}} document(s) uploaded successfully. You can close the modal window.',
  uploadDocsWarningMessage:
    '{{count}} document(s) uploaded successfully and {{failed}} failed. Retrying...',
  uploadDocSuccess: '{{count}} document uploaded successfully!',
  documentsUploadSuccess: 'Documents uploaded successfully!',
  searchDocuments: 'Search Documents',
  enterTagName: 'Enter tag name',
  folderCreateSuccess: 'Folder created successfully!',
  workspaceCreateSuccess: 'Workspace created successfully!',
  collectionCreateSuccess: 'Collection created successfully!',
  folderCreateError: 'Error creating resource!',
  folderCreateSuccessAccesError:
    'Folder created successfully!Failed to update access permission.',
  folderUpdateSuccessAccessError:
    'Folder updated successfully! Failed to update access permission.',
  selectTags: 'Select Tags',
  docTaggedSuccess: 'Documents tagged successfully!',
  docTaggedError: 'Error tagging documents!',
  docTagsDeleteSuccess: 'Tags deleted successfully!',
  docTagsDeleteError: 'Failed to delete tags!',
  docTagsUpdatedSuccess: 'Tags updated successfully!',
  docTagsUpdatedError: 'Failed to update tags!',
  selectedTags: 'Selected Tags',
  tags: 'Tags',
  selectUploadTags: 'Select tags to apply on uploaded documents',
  maxDocSize:
    'Document name length of "{{ name }}" exceeds the maximum limit of 200.',
  uploaded: 'Uploaded',
  status: 'Status',
  documentDeletionFailed: 'Failed to delete document.',
  attachedFile: 'Attached Files',
  documentNotEmptyError: 'Deleting a non-empty folder is not allowed.',
  uploadFolder: 'Upload Folder',
  documentDeleteSuccess: 'Document deleted successfully!',
  documentsDeleteSuccess: 'Documents deleted successfully!',
  documentDeleteConfirmation: 'Are you sure you want to delete the document?',
  collectionDeleteSuccess: 'Collection deleted successfully!',
  collectionDeleteConfirmation:
    'Are you sure you want to delete the collection?',
  collectionDeletionFailed: 'Failed to delete collection.',
  workspaceDeletionFailed: 'Failed to delete workspace.',
  workspaceDeleteSuccess: 'Workspace deleted successfully!',
  workspaceDeleteConfirmation: 'Are you sure you want to delete the workspace?',
  multipleDocumentDeleteConfirmation:
    'Are you sure you want to delete the selected documents?',
  nestedFolderDelete:
    'This action will delete all selected folders, subfolders, and files. Do you want to continue?',
  multipleFilesReprocessConfirmation:
    'Are you sure you want to reprocess the selected document?',
  genericConfirmation: 'Are you sure?',
  retrialFailed: 'Failed to upload some documents please try again later.',
  deleteDocuments: 'Delete Documents',
  cancelUploadConfirmation:
    'Are you sure you want to cancel uploading the remaining files?',
  retryFailedDocuments: 'Retry Failed Documents',
  processingFailed: 'Processing Failed',
  processLastTransaction: 'Process Last Transaction',
  processAllTransactions: 'Process All Transactions',
  documentDownloadError: 'Failed to download document.',
  destinationFolder: 'Select Destination Folder',
  move: 'Move',
  moveDuplicateError: 'File/Folder already exists.',
  moveSuccess: 'File/Folder moved successfully.',
  moveFailed: 'Error moving File/Folder.',
  reprocessSuccess: 'Files queued for reprocessing successfully.',
  reprocessFailed: 'Error reprocessing files.',
  reprocessCountInfo: 'Reprocessing {{count}} files.',
  syncSuccess: 'Resource queued for syncing successfully.',
  syncFailed: 'Error syncing resource.',
  nameCharacterLengthError: 'Name should be between 1 to 200 characters.',
  name: 'Name',
  resourceSharingSuccessMessage: 'Resource shared successfully.',
  resourceSharingErrorMessage: 'Error while sharing resource.',
  userOrUserGroup: 'User or User Group',
  searchForUserOrUserGroup: 'Search for User or User Group',
  message: 'Message',
  addMessage: 'Add a message',
  sendEmailNotificationToRecipients: 'Send email notification to recipients',
  advanced: 'Advanced',
  keepManuallyAssignedRolesOnSubFoldersFiles:
    'Keep already assigned roles on sub folders/files',
  assignRoleToAllSubFoldersFiles: 'Assign role to all sub folders/files',
  assignRoleOnFilesOnly: 'Assign role on files only',
  noUserOrUserGroupHasAccessToResource:
    'No User or User group has access to the Resource.',
  noMatchingUsersOrUserGroupsFound: 'No matching users or user groups found',
  controllerRoleAssignmentToRegularUserAtWorkspace:
    'Selected role can not be assigned to Regular Users at workspaces.',
  regularUserAssignmentToWorkspace:
    'Regular users can not be made a workspace controller. Promote account type from users page.',
  contollerAssignmentError:
    'Not authorised to invite users as controllers on workspaces.',
  authorizedToRemoveLowTierAccountTypesOnly:
    'Authorized to remove assignments for lower-tier account type only.',
  containsCollection: 'Contains: {{count}} Collections',
  containsItems: 'Contains: {{count}} Items',
  createPermissionDenied: 'Permission to create not found',
  uploadPermissionDenied: 'Permission to upload not found',
  createCollection: 'Create a Collection',
  collectionPlaceholder: 'Add documents within a collection to chat with them',
  createFolder: 'Create a Folder',
  folderPlaceholder: 'Add content to build up your knowledge domain',
  assignViewerSubtitle: 'Query and read the content.',
  assignControllerSubtitle: 'Query, edit and manage the access to the content.',
  assignContributorSubtitle: 'Query, read and add to the content.',
  assignAssistantViewer: 'Chat with the assistant',
  assignAssignController: 'Chat and control the assistant.',
  emptyWorkspace: 'No Workspace available',
  emptyCollection: 'No Collection available',
  workspaces: 'Workspaces',
  collections: 'Collections',
  licenseLimitReached: 'License limit reached',
  author: 'Author',
  importExternalDocs: 'Import from external application',
  documentDetailsDisclaimer: 'AI generated document overview may have errors.',
  disableImportMessage:
    'Importing from External Applications disabled in "{{workspaceName}}"',
  importNotAllowed: 'Import is not allowed in Public/Private Workspace',
  workspace: 'Workspace',
  file: 'File',
  collection: 'Collection',
  folder: 'Folder',

  // Chat
  Chat: 'Chat',
  chat: 'chat',
  createNewChat: 'New Chat',
  send: 'Send',
  queryPlaceholder: 'Ask.',
  queryPlaceholderWorldKnowledge: `Ask. Press '/' for accessing useful prompts.`,
  chatConfigurations: 'Chat configuration',
  parameterDialogTitle: 'Parameters',
  parameterDialogSubtitle:
    'Update chat parameters to search the documents efficiently',
  updateParameters: 'Update Parameters',
  cancel: 'Cancel',
  submit: 'Submit',
  save: 'Save',
  confirm: 'Confirm',
  parametersUpdateSuccess: 'Chat parameters updated successfully!',
  parametersUpdateError: 'Error updating chat parameters!',
  temperature: 'Temperature',
  tokens: 'Max length (tokens)',
  sequences: 'Stop sequences',
  probabilities: 'Top probabilities',
  frequency: 'Frequency penalty',
  presence: 'Presence penalty',
  chatRenameSuccess: 'Chat renamed successfully!',
  chatRenameFailed: 'Error renaming chat!',
  chatDeleteSuccess: 'Chat deleted successfully!',
  chatDeleteFailed: 'Error deleting chat!',
  generatingResponse: 'Generating response',
  errorGeneratingResponse: 'Could not generate response! Please try again!',
  messagesLoadingFailed: 'Error fetching messages. Please try again!',
  responseFailedMessage: 'There was an error generating a response.',
  responseStreamingFailedMessage:
    'There was an error in streaming response. Please refresh the page.',
  regenerateResponse: 'Regenerate Response',
  errorAddingReaction: 'Error adding reaction.',
  errorUpdatingChatContext: 'Error updating chat context.',
  chatDeleteConfirmation: 'Are you sure you want to delete the chat?',
  samplePrompts: 'Sample Prompts',
  capabilities: 'Capabilities',
  limitations: 'Limitations',
  chatGptSlogan:
    'For assistance in content creation, coding, research and access to world knowledge.',
  empowerGptSlogan: 'For answers in light of accessible knowledge base.',
  samplePrompt1:
    'What checks should I carry out before installing the Etaprime L pump?',
  samplePrompt2:
    'What is the allowable range of viscosity of Crude oil for L27/38S power plant?',
  samplePrompt4: 'Guidelines for creating pivot table in Microsoft Excel.',
  samplePrompt5: 'Remote team collaboration & communication tips.',
  samplePrompt7: 'Suggest supply chain optimization strategies.',
  samplePrompt8: 'Best practices for identifying corporate risks.',
  capabilities1:
    ' Get answers for your queries solely based on the uploaded documents.',
  capabilities2:
    'Remembers the context of the previous questions within a chat.',
  limitations1: ' May fail to compare attributes spread across the database.',
  limitations2: 'Knowledge confined to the scope of the uploaded documents.',
  includeChatHistory: "Click to reconnect with last query's context.",
  excludeChatHisory: 'Click to disconnect from chat context',
  stickToDocs: 'Limits search to documents open in viewer.',
  resourceUnavailable: 'Resource Unavailable',
  referenceDocumentDeleted: 'Reference document deleted',
  disclaimerChatgpt:
    'AI Model can make mistakes, please consider verifying important information.',
  copy: 'Copy',
  copied: 'Copied',
  errorCopyingResponse: 'Error copying response',
  stickDocMessage:
    'Limits search to documents open in viewer. (No documents opened)',
  contentFilteringErrorMsg: `The response was filtered due to the prompt triggering Azure's content management policy. Please modify your prompt and retry.`,
  generalErrorMsg:
    'The AI Model was not able to generate a response. Please try again.',
  shareChatTitle: 'Share this chat',
  shareChatText: 'EmpowerGPT users with the link',
  shareChatDisclaimer: 'Any messages you add after sharing will stay private',
  copyLink: 'Copy Link',
  errorCopyingLink: 'Error copying link',
  sharedBy: 'Shared By: ',
  chatInitiationError:
    'Could not initiate a request, Please check if model configuration is correct.',
  rateLimit:
    'Your organization has reached the rate limit for requests per minute. Please try again later.',
  unsupportedLanguage: `Input language detected as {{detectedLanguage}} is currently not supported for Enterprise Knowledge chat. Please switch to a supported language, which currently includes English, German, and Italian.`,
  webSearchDescription: 'Searches the web to answer',
  stickToDoc: 'Stick to docs',
  webSearch: 'Web search',
  noAuthorizedFilesForChat:
    'Sorry, No documents against the selected resource are available for chat. Please ensure the it is not empty, and contains processed documents accessible to you.',
  addFilesDescription: 'Add Files for focused responses',
  documentsInFocus: 'Documents in focus:',
  advancedSearchButtonText: 'Switch to Advanced Search',
  showInFolder: 'Show in Folder',
  findDocumentsText: 'Type to find documents in Workspaces',
  noResultsFound: 'No results found',
  startChatOnSelectedFiles: 'Start chat on selected files',
  addSelectedFilesToChat: 'Add Selected Files to Chat',
  noResultsTitle: 'No Results for',
  noResultsDescription:
    'Try a different search phrase, check for typos, or modify any active filters.',
  advancedSearchTitle: 'AI-Powered search on your Enterprise Knowledge',
  advancedSearchDescription:
    'Find relevant documents and information within accessible content in Workspaces.',
  assistantDeletedError: 'Assistant has been deleted',
  fileDeletedOrInaccessibleDesc:
    'has been deleted or is no longer accessible to you',
  fileDeletedOrInaccessibleLabel: 'Inaccessible',
  imageAnalysisNotSupported:
    'Selected model does not support images. Please switch to a model that supports image analysis.',

  //Chat Steps
  understandingInput: 'Understanding input',
  thinking: 'Thinking',
  searchingContent: 'Searching workspaces',
  searchingWeb: 'Searching web',
  searchingAttachments: 'Searching within attachment(s)',
  attachedDocuments: 'document(s)',
  link: 'link',
  generatingResponseUsing: 'Generating response using',
  excerptsFrom: 'excerpts from',
  contentSearched: 'Searched Enterprise Knowledge',
  webSearched: 'Searched Web',
  searchedAttachments: 'Searched within attachment(s)',

  // Chat feedback ...
  feedbackTitle: 'Provide additional feedback',
  feedback1: 'Response cites wrong document.',
  feedback2: 'Response out of scope of documents.',
  feedback3: 'Response is harmful/unsafe.',
  feedback4: 'Response is not helpful.',
  submitFeedback: 'Submit feedback.',
  sources: 'Sources',
  feedbackCommentPlaceholder:
    'What went wrong with the answer? How can it be imporved?',
  feedbackValidationError: 'Please select atleast 1 option.',

  // User/Roles/Permissions
  rolesTitle: 'Roles',
  role: 'Role',
  permissions: 'Permissions',
  users: 'Users',
  create: 'Create',
  update: 'Update',
  add: 'Add',
  new: 'New',
  edit: 'Edit',
  delete: 'Delete',
  tag: 'Tag',
  upload: 'Upload',
  createRole: 'Create Role',
  updateRole: 'Update Role',
  createUser: 'Create User',
  updateUser: 'Update User',
  searchRole: 'Search User Role',
  availableRole: 'Available User Role',
  assignedRole: 'Assigned User Role',
  confirmDeleteRole: 'Are you sure you want to delete this role?',
  confirmDeleteUser: 'Are you sure, you want to delete this user?',
  confirmDeleteYourAccount: 'Are you sure, you want to delete your account?',
  confirmDeleteMultipleUsers: 'Are you sure, you want to delete these users?',
  roleDeleteSuccess: 'Role deleted successfully!',
  deleteRow: 'Delete row',
  randomError: 'Some Error Occurred!',
  errorDeletingRole: 'Error Deleting Role.',
  noRecordFound: 'No Record Found!',
  roleUpdateSuccess: 'Role Updated Successfully!',
  roleUpdateError: 'Error updating role!',
  roleCreateSuccess: 'Role Created Successfully!',
  roleCreateError: 'Error creating role!',
  userInviteSuccess: 'An email invite has been sent to invited users.',
  userInviteError: 'Error while inviting users.',
  userUpdateSuccess: 'User updated successfully!',
  userUpdateFailed: 'Error while updating user!',
  userDeleteSuccess: 'User deleted successfully!',
  usersDeleteSuccess: 'Users deleted successfully!',
  userDeleteError: "Couldn't delete user, Please try again!",
  duplicateUserError: 'Email is already Taken',
  duplicateFolderError: 'File/Folder already exists!',
  duplicateCollectionError: 'Collection already exists!',
  duplicateWorkspaceError: 'Workspace already exists!',
  renameSuccess: 'Renamed successfully!',
  renameFailed: 'Error renaming!',
  rename: 'Rename',
  enterRoleName: 'Enter Role Name',
  fullName: 'Full Name',
  enterFullName: 'Enter Full name',
  enterEmailAddress: 'Enter Email Address',
  enterRoleDescription: 'Enter Role Description',
  roleName: 'Role Name',
  invalidRoleName: 'Invalid Role Name',
  userName: 'Username',
  enterUserName: 'Enter User name',
  moveSelectedRight: 'move selected right',
  moveSelectedLeft: 'move selected left',
  applyTags: 'Apply Tags',
  language: 'Language',
  dataLibrary: 'Data Library',
  documentsStatus: 'Uploaded Documents Status',
  statusFailedText: 'Status : Failed',
  statusUploadedText: 'Status : Uploaded',
  startDate: 'Start Date',
  endDate: 'End Date',
  selectStatus: 'Select Status',
  pages: 'pages',
  addARole: 'Add a Role',
  editRole: 'Edit Role',
  tagHelperText:
    "Press the 'Enter' key to create a new tag or to select one from the list",
  organizationAdminSubtitle:
    'Can create and assign workspaces, invite admins/users, add custom roles.',
  workspaceAdminSubtitle:
    'Can manage complete workspace(s), user groups, and add regular users.',
  regularUserSubtitle:
    'Can chat accessible documents, upload and share content with other users.',
  maxChatFileSize:
    'The file "{{ name }}" is too large. The maximum size for text files is "{{ size }}mb".',
  maxUploadFile:
    'The limit for maximum number of attached files is "{{ files }}".',
  unSupportedFile:
    'The File "{{ name }}" is not uploaded due to unsupported format.',
  webSearchOnMsg: 'Disable web search to enable file attachment functionality',
  chatFileName: ' {{ status }} {{ name }}',
  uploading: 'Uploading',
  processing: 'Processing',
  processed: 'Processed',
  unsupportedFile:
    'Unsupported {{ name }}. Please remove the attachment to send the message',
  uploadFailed: 'UPLOAD_FAILED',
  retryMsg: 'Error uploading {{ name }}. Please retry.',
  documentRemoved: 'Document removed successfully',
  unsupportedModelForImage:
    'Selected model does not support images, switch to a model that supports image analysis.',
  uploadFailedText: 'Upload failed. Retry',
  // workspace
  workspaceName: 'Workspace Name',
  collectionName: 'Collection Name',
  workspaceColor: 'Workspace Color',
  workspaceController: 'Workspace Controllers',
  createWorkspace: 'Create Workspace',
  updateWorkspace: 'Update Workspace',
  newWorkspace: 'New Workspace',
  newCollection: 'New Collection',
  newFolder: 'New Folder',
  documentUpdateConfirmation: 'Rename workspace and update background color',
  backgroundColor: 'Background Color',
  updatesOn: 'Updates',
  updatedBy: 'Updated By',
  workspaceUpdateSuccess: 'Workspace updated successfully!',
  workspaceUpdateFailed: 'Error updating workspace!',
  sortBy: 'Sort By',
  lastUpdated: 'Last Updated',
  ago: 'ago',
  day: 'day',
  days: 'days',
  hour: 'hour',
  hours: 'hours',
  min: 'min',
  mins: 'mins',
  sec: 'sec',
  secs: 'secs',
  workSpaceDocumentsDropError:
    'Add a collection to upload the documents inside.',
  createAWorkspace: 'Create a Workspace',
  newWorkspaceDescription:
    'Create a separate knowledge base of each department',
  publicDisclaimer: 'Content in Public is shared with all users.',
  privateDisclaimer: 'Content in My Workspace is only accessible to you.',
  moreActions: 'More actions',

  // create collection
  createACollection: 'Create a Collection',
  //no_data_messages
  noDataNameSpaceMessage: 'Create a workspace to start uploading data within.',
  noDataWorkspaceMessage:
    'Upload a folder or create a collection to upload data within.',
  noDataCollectionMessage: 'Upload files/folders',
  noDataNoPermissionMessage: 'Request administrator for access to documents.',
  noData: 'No Data',

  //no_content_msg
  noContentAvailable: 'No content available.',

  workSpaceInfoMessage:
    'New workspaces can only be created by organization administrators.',
  moveDialogInfo:
    'Moved content will maintain its original source permissions.',
  // File statuses
  F_S_UP: 'In progress',
  F_S_RE: 'Retrying',
  F_S_PR: 'Processed',
  F_S_US: 'Unsupported',
  F_S_LLR: 'License Limit Reached',
  F_S_WF: 'Waiting for file',

  // User Groups
  userGroupDeletedSuccessfully: 'User Group Deleted Successfully.',
  userGroupsDeletedSuccessfully: 'User Groups Deleted Successfully.',
  userGroupCreatedSuccessfully: 'User Group Created Successfully.',
  failedToCreateUserGroup: 'Failed To Create User Group.',
  failedToDeleteUserGroup: 'Failed To Delete User Group.',
  failedToDeleteUserGroups: 'Failed To Delete User Groups.',
  userGroupUpdatedSuccessfully: 'User Group Updated Successfully.',
  failedToUpdateUserGroup: 'Failed To Update User Group.',
  addUserGroup: 'Add a User Group',
  editUserGroup: 'Edit User Group',
  searchUsers: 'Search Users',
  createdBy: 'Created By',
  userGroupDeleteConfirmationMsg: 'Are you sure to delete user group(s)?',

  //validations
  maxLength: '{{name}} cannot be longer than {{length}} characters',
  required: '{{name}} is required',
  maxCompanyLength: '{{company}} cannot be longer than {{length}} characters',
  maxDepartmentLength:
    '{{department}} cannot be longer than {{length}} characters',
  maxJobLength: '{{jobRole}} cannot be longer than {{length}} characters',
  maxCountryength: '{{country}} cannot be longer than {{length}} characters',

  // Error Messages
  namespaceError: 'Namespace error occurred.',
  resourceNotFoundError: 'Resource not found.',
  noPermissionsAgainstResourceError: 'No permissions against the resource.',
  appRoleTemplateNotFoundError: 'App role template not found.',
  insufficientPrivileges: 'Permission to perform the action not found.',
  uploadTypeError: 'Invalid upload type provided.',
  transactionIdError: 'Invalid Transaction ID provided.',
  uploadToRootError: 'Error uploading to root.',
  uploadToFoundError:
    'You are not allowed to upload files and folders in FOUND folder.',
  uploadToFileError: 'Files can only be uploaded in valid folders.',
  fileUploadToWorkspaceError: 'Files cannot be uploaded on workspace level.',
  noFilesAttachedError: 'Select valid files to start uploading them.',
  fileStorageWriteError: 'File storage write error.',
  fileStorageReadError: 'File storage read error.',
  requestedResourceNotFile: 'Requested resource is not a file.',
  fileStorageDeleteError: 'File storage delete error.',
  publicFolderDeleteError: 'Public folder cannot be deleted.',
  internalServerError: 'Something went wrong.',
  invalidArgsError: 'Invalid arguments provided.',
  folderNotAllowedHere: 'You are not allowed to create folder here.',
  rolesInsufficientViewPrivileges: "You don't have right to view roles.",
  userGroupsInsufficientPrivileges: "You don't have right to view user groups.",
  waCreateUserRestriction: 'Workspace admin can only create regular users.',
  readOnlyResourceUpdate: 'You are not allowed to update readonly resource.',
  readOnlyResourceDelete: 'You are not allowed to delete readonly resource.',
  rolesUniqueVoilation: 'Role with the name already exists.',
  addPermissionsAgainstResourceError:
    'Failed to add permissions against resource.',
  permissionsUpdatingErrorForRole:
    'Failed to update permissions for the resource.',
  insufficientCreatePrivileges: "You don't have rights to create resource.",
  insufficientUpdatePrivileges: "You don't have rights to update resource.",
  insufficientDeletePrivileges: "You don't have rights to delete resource.",
  insufficientDeleteOneOrMorePrivileges:
    "You don't have rights to delete some resources.",
  userGroupUniqueVoilation: 'User group with the same name already exists.',
  usersUniqueVoilation: 'User already exists!',
  selfAssignmentOnResourceRestriction:
    'You cannot assign yourself to resource.',
  ruAssignmentAsControllerRestriction:
    'Regular users cannot be assigned as controller.',
  oaRemovalFromResourceDenied:
    'Organization Administrator cannot be removed from resource.',
  insufficientAssignmentPrivileges:
    "You don't have enough rights to perform the operation.",
  peerAccountAssignmentRestriction:
    'You are not allowed to remove same or higher level accounts.',
  messageOnEmptyFolderOrUnavailableFile:
    'Chat cannot be initiated on empty or unavailable resources.',
  noSharedFilesExist:
    'Please upload documents to the workspaces or request admin for access to start using the questioning.',
  itemDetailsNotFoundError: 'Details for this resource could not be found.',
  chatCreditLimitExceededOA:
    'Monthly limit for AI credits reached. For immediate assistance, contact customer support at “support@empowergpt.ai”',
  chatCreditLimitExceeded:
    'Monthly limit for AI credits reached. Please contact your administrator.',
  fileCreditLimitExceededOA:
    'Document processing halted, monthly limit for AI credits reached. For immediate assistance, contact customer support at “support@empowergpt.ai”. ',
  fileCreditLimitExceeded:
    'Document processing halted, monthly limit for AI credits reached. Please contact your administrator.',
  fileWordLimitExceededOA:
    'Document processing halted, word limit for processed documents stored has been reached. Please delete documents that are no longer required to free up space. For further assistance, contact customer support at “support@empowergpt.ai”.',
  fileWordLimitExceeded:
    'Document processing halted, word limit for processed documents stored has been reached. Please delete documents that are no longer required to free up space.',
  creditLimitExceeded90Percent: 'Over 90% of AI credits have been consumed.',
  wordLimitExceeded90Percent:
    'Over 90% of allocated word limit against processed documents stored has been reached',
  wordLimitReached100Percent:
    'Allocated word limit for processed documents stored has been reached. Please delete documents that are no longer required to free up space. For further assistance contact customer support at “support@empowergpt.ai”',
  fileUploadLimitError:
    'You have reached the Trial plan limit of uploading 100 documents.',
  // resource provider errors
  operationNotPermitted: 'Permission to perform the action not found.',
  // Usage
  processedDocuments: 'Processed Documents',
  overallUsage: 'Overall Usage',
  aiUsage: 'AI Usage',
  monthlyUsage: 'Monthly Usage',
  dailyUsage: 'Daily Usage',
  processedDocumentsInfo:
    'Estimated number of words against processed documents stored in workspaces.',
  tokenUsageInfo:
    '{{consumed}} {{unit}} of processed documents stored in the workspaces out of the {{total}} {{unit}} limit',
  aiUsageInfo: 'AI usage for chat, and document processing',
  creditUsageInfo:
    '{{consumed}} {{unit}} used out of the {{total}} {{unit}} limit',
  credits: 'Credits',
  consumed: 'Consumed',
  date: 'Date',
  filesPermission: 'Files are not allowed here',
  noCreditsUsedThisMonth: 'No credits used this month',
  uniqueFolderNameError: 'Folder already exists.',
  noResourcesShared:
    'Authorized documents are allowed only. Please upload a document first.',
  // users invitations
  inviteUsers: 'Invite Users',
  emailAddress: 'Email Address',
  userGroup: 'User Group',
  active: 'Active',
  invited: 'Invited',
  invitationExpired: 'Invitation Expired',
  inactive: 'Inactive',
  resendInvite: 'Resend Invite',
  deActivate: 'Deactivate',
  activate: 'Activate',
  inviteSentSuccessfully: 'Invite sent successfully!',
  inviteSentFailed: 'Error sending invite!',
  userActivated: 'User activated successfully!',
  userDeactivated: 'User deactivated successfully!',
  userActivationFailed: 'Error activating user!',
  userDeactivationFailed: 'Error deactivating user!',
  adUserNotFoundError: 'User not found in AD.',
  invalidEmail: 'Please enter a valid email address',

  // SharePoint
  sharePointAuthError:
    'You might not be able to interact with SharePoint due to connectivity issue!',
  sharepoint: 'Import from SharePoint',
  sharepointModalTitle: 'Select a Folder / Library',
  sharepointModalSubtitle:
    'Navigate to the desired site, and select the target folder / library for importing',
  availableSites: 'Available Sites',
  selectSiteMsg: 'Select a site to view available site content',
  siteContent: 'Site Content',
  showSiteContent: 'Show Site Content',
  select: 'Select',
  resourceImportedSuccessfully: 'Resource imported successfully.',
  rootSite: 'Root Sites',
  sharePointSuccessModalTitle: 'Folder / Library Linked Successfully',
  externalImportSuccessModalLine1:
    'All set! Selected folder / library along with its contents shall be accessible to you as a Collection once the data sync completes.',
  externalImportSuccessModalLine2:
    'The Collection’s visibility is solely based on its permissions at {{ name }}.',
  noSubSiteAvailable: 'No Sub site available.',
  noFolderFileAvailable: 'No Folder / File available.',
  openInSharePoint: 'Open in SharePoint',
  collectionSharepointPlaceholder: 'Import from Sharepoint',
  collectionSharepointDescription:
    'Import content from Sharepoint to EmpowerGPT',

  // One Drive
  oneDriveMyFiles: 'My Files',
  oneDriveShared: 'Shared with you',
  oneDrive: 'Import from OneDrive',
  oneDriveModalTitle: 'Select a Folder / Library',
  oneDriveModalSubtitle:
    'Navigate within your OneDrive, and select the target content for importing',
  content: 'Content',
  blog: 'Blog',
  oneDriveSuccessModalTitle: 'Folder / Library Linked Successfully',
  oneDriveSuccessModalLine1:
    'All set! Selected folder / library along with its contents shall be accessible to you as a Collection once the data sync completes.',
  oneDriveSuccessModalLine2:
    'The Collection’s visibility is solely based on its permissions at OneDrive.',
  openInOneDrive: 'Open in OneDrive',
  collectionOneDrivePlaceholder: 'Import from OneDrive',
  collectionOneDriveDescription: 'Import content from OneDrive to EmpowerGPT',

  // common external resource provider
  collectionExternalResProvPlaceholder: 'Import from Sharepoint / OneDrive',
  collectionExternalResProvDescription:
    'Import content from Sharepoint or OneDrive to EmpowerGPT',
  externalResourceDisclaimer:
    "The content's visibility is solely based on its permissions at source except within “Public” or “My Workspace",

  // SSO configuration
  redirectUri: 'Redirect URI',
  signInWithMicrosoft: 'Sign in with Microsoft (SSO)',
  allowInvitingExternalUsers: 'Allow inviting external users',
  limitUserInvitation: 'Limit user invitation to specified email domain',
  limitUserInvitationPlaceholder: 'example1.com, example2.com',
  externalDriveIntegration: 'Allow Content Import from External Applications',
  syncFrequency: 'Sync Frequency (mins)',
  contentImportFromSharepoint: 'Microsoft Sharepoint',
  contentImportFromOneDrive: 'Microsoft OneDrive',
  contentImportFromConfluence: 'Atlassian Confluence',

  // steps to configure SSO
  letsStart: "Let's start",
  next: 'Next',
  done: 'Done',
  welcomeScreenHeading1: 'Steps for SSO',
  welcomeScreenHeading2: 'Configuration',
  registrationStep: 'Register “EmpowerGPT” in Microsoft Entra ID',
  azureDetailsStep:
    'Enter details against the registered application on Microsoft Entra ID',
  configurePermissions:
    'Give required permissions against used APIs to the registered app in Microsoft Entra ID',

  // SSO configuration RegistrationStep
  registrationPoint1: 'In another tab, sign in to',
  azurePortal: 'Azure Portal',
  roleAzurePortal: 'as an admin.',
  registrationPoint2:
    'Navigate to "Microsoft Entra ID" (Azure Active Directory) > "App registrations" as an admin.',
  registrationPoint3: 'Click on "+ New registration".',
  registrationPoint4:
    'Enter “EmpowerGPT” as the name of the application and select the required supported account types. (Recommended: “Accounts in this organizational directory only”).',
  registrationPoint5:
    'Under the “Redirect URI” section on the application registration form, select “Web” as the type and paste the following Redirect URI in the corresponding field.',
  confirmationStep1:
    'The above steps have been performed on the registered application in Microsoft Entra ID.',
  // Registration Step form validation
  redirectUriRequired: 'Redirect URI is required',

  // SSO configuration Azure Details Step
  azureDetailsPoint1:
    "Once the application is registered, you will be redirected to the registered application's “Overview” page.",
  azureDetailsPoint2:
    'Copy the “Application (client) ID” and “Directory (tenant) ID” from the “Overview” page and enter each it in the respective fields below.',
  azureDetailsPoint3:
    'Navigate to “Certificates and secrets” under the “Manage” section on the registered application in Microsoft Entra ID.',
  azureDetailsPoint4:
    ' Select the “Client secrets” tab and click “New client secret” button to create a new client secret. Enter “EmpowerGPT client secret” in description, set the expiry to 24 months (recommended) and click “add”.',
  azureDetailsPoint5:
    'Copy the secret "Value" and paste it in the field below. Client secret values cannot be viewed. except for immediately after creation. Be sure to save the secure value when created before leaving the page.',
  // Azure Details Step form validation
  clientIdRequired: 'Client Id is required',
  labelClientId: 'Application (client) Id: ',
  tenantIdRequired: 'Tenant Id is required',
  labelTenantId: 'Directory (tenant) Id:',
  secretValueRequired: 'Secret Value is required',
  labelSecret: 'Client Secret Value:',
  enter: 'Enter...',
  // SSO configuration Permissions Step
  permissionsStep1:
    'Navigate to “API permissions” under the “Manage” section on the registered application in Microsoft Entra ID.',
  permissionsStep2:
    'Click "+ Add a permission" button and select "Microsoft Graph" under Microsoft APIs.',
  permissionsStep3:
    'Select "Application permissions" to give the following permissions and confirm by clicking "add permission" button below.',

  permissionsStep4: 'User.Read.All under User.',
  permissionsStep5: 'Group.Read.All under Group.',
  permissionsStep6: 'GroupMember.Read.All under GroupMember.',
  permissionsStep7:
    'Repeat step 2 and select "Delegated permissions" to give the "profile" permission. Confirm by clicking "add permission" button below.',
  permissionsStep8:
    'The above steps have been performed on the registered application in Microsoft Entra ID.',

  successDialogTitle: 'SSO Configured Successfully',
  successDialogDescription:
    'SS0 has been successfully configured against users within the linked directory.',
  clickHere: 'Click here',
  toLogout: ' to logout and test the configuration.',
  // Resource Provider config
  resourceProviderWelcomeTitle: 'Steps to Configure Importing from SharePoint',
  oneDriveResourceProviderWelcomeTitle:
    'Steps to Configure Importing from OneDrive',
  resourceProviderTitle1:
    'Upload generated certificate at registered application “EmpowerGPT” on Microsoft Entra ID',
  resourceProviderStep1Instruction1:
    "Paste the base URL for your organization's SharePoint below. (The base URL for SharePoint Online is provisioned based off your input when setting up your Microsoft 365 subscription. For example, if your organization name is Contoso, the root site for SharePoint Online will be https://contoso.sharepoint.com.).",
  resourceProviderStep1Instruction2:
    'Click the button below to download the required certificate.',
  resourceProviderStep1Instruction3:
    'In another tab, sign in to Azure Portal as an admin.',
  resourceProviderStep1Instruction4:
    'Navigate to “Microsoft Entra ID” (Azure Active Directory) > “App registrations” > ”All applications” > ”EmpowerGPT”.',
  resourceProviderStep1Instruction5:
    'Select the application and navigate to “Certificates and secrets” under the Manage section.',
  resourceProviderStep1Instruction6:
    'Select the “Certificates” tab and click on upload certificate. Upload the above generated certificate, downloaded on your computer.',
  resourceProviderStep1Instruction7:
    'Enter suitable description and click the "Add" button to add the certificate.',
  resourceProviderTitle2:
    'Give required permissions against used APIs to the registered app in Microsoft Entra ID',
  resourceProviderStep0Instruction0Start: `In another tab, sign in to `,
  resourceProviderStep0Instruction0End: ` as an admin`,
  resourceProviderStep2Instruction1:
    'Navigate to “Microsoft Entra ID” (Azure Active Directory) > “App registrations” > ”All applications” > ”EmpowerGPT” > “API Permissions”.',
  resourceProviderStep2Instruction2:
    'Click “+ Add a permission” button and select “Microsoft Graph” under Microsoft APIs.',
  resourceProviderStep2Instruction3:
    'Select “Application permissions” to give the following permissions and confirm by clicking “add permission” button below.',
  resourceProviderStep2Instruction5: 'Sites.Read.All.',
  resourceProviderStep2Instruction6: 'Files.Read.All.',
  resourceProviderStep2Instruction7:
    'Repeat step 2 and select "Delegated permissions" to give the following permissions. Confirm by clicking "add permission" button below.',
  resourceProviderStep2Instruction8:
    'Click "+ Add a permission" button and select "SharePoint" under Microsoft APIs to give the following "Application permission". Click "add permission" to confirm.',
  sharepointConfigurationSuccessTitle: 'Importing from SharePoint Configured',
  sharepointConfigurationSuccessDescription1:
    'Content can now be imported from SharePoint sites in EmpowerGPT as collections.',
  sharepointConfigurationSuccessDescription2:
    "Imported content's visibility will be based on its permissions at SharePoint.",

  oneDriveConfigurationSuccessTitle: 'Importing from OneDrive Configured',
  oneDriveConfigurationSuccessDescription1:
    'Content can now be imported from OneDrive in EmpowerGPT as collections.',
  oneDriveConfigurationSuccessDescription2:
    "Imported content's visibility will be based on its permissions at OneDrive.",
  Navigate: 'Navigate',
  downloadCertificate: 'Download Certificate',
  invalidSharePointUrl: 'Invalid SharePoint URL provided.',
  identityProviderEnabledSuccessfully: 'Microsoft (SSO) enabled successfully.',
  identityProviderDisabledSuccessfully:
    'Microsoft (SSO) disabled successfully.',

  identityProviderUpdateFailed: 'Error updating Microsoft (SSO).',
  resourceProviderUpdateFailed: 'Error updating SharePoint.',
  identityProviderCreationFailed: 'Error enabling Microsoft (SSO).',
  disabledToolTipMicrosoftSSO: 'Configure Microsoft SSO to enable.',
  requiredCheckbox: 'This field is required.',
  adminConsent:
    'Make sure admin consent is provided for permissions that require it, indicated by a “Yes” in the “Admin consent required” column of the “Configured permissions” table.',

  resourceProviderSharePointEnabledSuccessfully:
    'SharePoint enabled successfully.',
  resourceProviderOneDriveEnabledSuccessfully: 'OneDrive enabled successfully.',
  resourceProviderSharePointDisabledSuccessfully:
    'SharePoint disabled successfully.',
  resourceProviderOneDriveDisabledSuccessfully:
    'OneDrive disabled successfully.',
  resourceProviderSharePointCreationFailed: 'Error enabling SharePoint.',
  resourceProviderOneDriveCreationFailed: 'Error enabling OneDrive.',
  //Internal Analytics
  analytics: 'Analytics',
  questions: 'Questions',
  dislikes: 'Dislikes given',
  analysisPerformed: 'Analysis Performed',
  questionnairesCreated: 'Questionnaires Created',
  questionsAdded: 'Questions Added',
  // User Favourites
  favourites: 'Favourites',
  markFavourite: 'Mark as favourite',
  unmarkFavourite: 'Unmark as favourite',
  markedFavourite: 'Marked as favourite',
  unmarkedFavourite: 'Unmarked as favourite',
  markFavouriteError: 'Error marking as favourite',
  unmarkFavouriteError: 'Error unmarking as favourite',
  favouriteLimitReached: 'User favourites limit reached',
  // user onboard
  welcomeText: 'Welcome to',
  welcomeText1:
    'EmpowerGPT is an AI Platform made for Enterprises. Chat securely with state-of-the-art LLMs incorporating your enterprise and world knowledge.',
  welcomeText2: `Expand your enterprise’s knowledge base by uploading, or importing data from integrated applications.`,
  // User Onboarding
  //Chat
  gotIt: 'Got it',
  onNext: 'Click Next to continue.',
  chatStep1Title: 'Chat: Select the required knowledge domain.',
  chatStep1Description: `Choose how you want EmpowerGPT to respond. Stick to enterprise knowledge for answers in light of accessible enterprise documents. Or switch to world knowledge for assistance in content creation, coding, research and access to world knowledge.`,
  chatStep2Title: 'Chat: Type in your query.',
  chatStep2Description: `Use the input field below to enter your queries, and hit the send button on the right. Context against prior queries and responses is limited within a chat`,
  chatStep3Title: 'Chat: Review the facts.',
  chatStep3Description: `Chat responses in enterprise knowledge have references cited at the end. It is recommended to validate the authenticity of the response by viewing the references.`,
  chatQuery:
    'Can you write an email to congratulate the team on achieving their quarterly targets?',
  today: 'Today',
  thisWeek: 'This week',
  thisMonth: 'This month and older',
  chatHistory: 'Chat History',

  // Data Library
  documentStep1Title: 'Upload: Navigate to the Workspaces',
  documentStep1Description:
    'Workspaces categorize documents within an organization.',
  documentStep2Title: 'Upload: Select the required Workspace.',
  documentStep2Description: `Actions available on a workspace are based on the role you have been assigned on them. “My Workspace” is a personal workspace, you can add documents and chat with.`,
  documentStep3Title: 'Upload: Create a collection.',
  documentStep3Description: `Collections further classify documents within each workspace. Create your collection within the My workspace to upload documents inside.`,
  documentStep4Title: 'Upload: Select documents to upload',
  documentStep4Description: `EmpowerGPT supports a wide variety of document formats for users to add to the knowledge base, including PDFs (scanned documents as well), Word documents, Powerpoint slides, and Excel files.`,
  documentStep5Title: 'Upload: Understanding document status',
  documentStep5Description: `Documents once uploaded are queued for AI processing. Once marked as “Processed”,  users who have access to these documents can chat with them.`,

  // Invite User
  inviteUserStep1Title: 'Invite: Open up the menu',
  inviteUserStep1Description:
    'Menu buttons contains links to pages required to setup, manage the application, and your account.',
  inviteUserStep2Title: 'Invite: Navigate to the users page',
  inviteUserStep2Description:
    'Users page contains list of all people currently on the empowerGPT platform. Admins can invite users from this page.',
  inviteUserStep3Title: `Invite: Type in the invitee’s email`,
  inviteUserStep3Description:
    'Type the emails for the invitees, select their account type, and user groups if any, before sending them the invite.',

  // Chat models comments and descriptions
  mistralLargeComment: 'Alternate',
  mistralLargeDescription: 'Hosted on Microsoft Azure in the EU',
  llama370BInstructComment: 'Open Source',
  llama370BInstructDescription: 'Hosted on Microsoft Azure in the EU',
  llama38BInstructComment: 'Open Source',
  llama38BInstructDescription: 'Hosted on Microsoft Azure in the EU',
  gpt4TurboComment: 'Accurate',
  gpt4TurboDescription: 'Hosted on Microsoft Azure in the EU',
  gpt432kComment: 'Best',
  gpt432kDescription: 'Hosted on Microsoft Azure in the EU',
  gpt4oComment: 'Fast & Accurate',
  gpt4oDescription: 'Hosted on Microsoft Azure in the EU',
  cohereComment: 'Enterprise Optimized',
  cohereDescription: 'Hosted on Microsoft Azure in the EU',
  gpt35Turbo16kComment: 'Fast',
  gpt35Turbo16kDescription: 'Hosted on Microsoft Azure in the EU',
  accuracy: 'Accuracy',
  speed: 'Speed',
  price: 'Price',
  tokenLimit: 'Token Limit',
  imageAnalysis: 'Image Analysis',
  knowledge: 'Knowledge Cutoff',
  // Prompts
  promptTitle1: 'Make shorter',
  promptText1:
    'Condense the following text to [insert_word_count] words, ensuring all important information is retained: [insert_text_here].',
  promptTitle2: 'Make longer',
  promptText2:
    'Expand the following text to [insert_word_count] words, ensuring no irrelevant details are added: [insert_text_here].',
  promptTitle3: 'Change tone',
  promptText3:
    'Rewrite the following text in a [specified_tone] tone, ensuring the message remains clear and effective: [insert_text_here].',
  promptTitle4: 'Fix grammar & spellings',
  promptText4:
    'Correct the grammar and spelling in the following text, highlighting all changes by making the corrected words bold: [insert_text_here].',
  promptTitle5: 'Translate',
  promptText5:
    'Translate the following text into [specified_language], ensuring that all named entities, abbreviations, and acronyms are accurately preserved: [insert_text_here].',
  promptTitle6: 'Summarize',
  promptText6:
    'Summarize the following text to [word_count] words, ensuring all important information is retained and the overall message remains unchanged: [insert_text_here].',
  promptTitle7: 'Give feedback',
  promptText7:
    'Provide feedback on the following text, focusing on the specified areas: [technical, tone, grammar, format]. Ensure your feedback is constructive and helps improve the text: [insert_text_here].',
  promptTitle8: 'Brainstorm ideas',
  promptText8:
    'Brainstorm [number_of_ideas] creative and practical ideas for the following task: [task+optional_examples]',
  promptTitle9: 'Write an email',
  promptText9:
    'Write an email based on the convey the following message: [message]. Ensure the tone is [specified_tone] and length of the email is [specified_length].',
  promptTitle10: 'Write a social media post',
  promptText10:
    'Write a social media post for [specified_platform] keeping a [specified_tone] tone, targeted at [target_audience]. Include engaging and platform-appropriate content.',

  // Mini Experts - Doc Analyst
  miniExperts: 'Mini Experts',
  docAnalyst: 'Doc Analyst',
  docAnalystDescription: `Doc Analyst is an Gen AI Mini Expert that can analyze Enterprise data on custom-crafted questions. The top features of Doc Analyst are listed below:`,
  docClassifierDescription:
    'Document classifier is a Gen AI Mini Expert designed to automatically categorize and label documents based on their content.',
  docSummarizerDescription:
    'Summary Generator automatically condenses lengthy documents into concise summaries, capturing the most critical points and essential information.',
  analysis: 'Analysis',
  documentClassification: 'Document Classification',
  comingSoon: 'Coming Soon',
  summaryGenerator: 'Summary Generator',
  dataCollections: 'Data Collections',
  initiate: 'Initiate',
  newAnalysis: 'New Analysis',
  initiateAnalysis: 'Initiate Analysis',
  confirmationDialog: 'Confirmation Dialog',
  dataset: 'Dataset',
  questionnaire: 'Questionnaire',
  runAnalysis: 'Run Analysis',
  runAnalysisPlaceholder:
    'To initiate Analysis, enter Analysis Name.\nSelect Dataset and Questionnaire, then click on “',
  importFromApp: 'Import from App',
  regenerateAnalysis: 'Regenerate Analysis',
  saveAnalysis: 'Save Analysis',
  clone: 'Clone',
  numberOfFiles: 'No. of files',
  numberOfQuestions: 'Ques. count',
  filesImportSuccess: 'Files/Folder imported successfully',
  filesImportFailure: 'Files/Folder import failed',
  import: 'Import',
  analysisTitle: 'Analysis Title',
  datasetTitle: 'Dataset Title',
  enterAnalysisTitle: 'Enter analysis title',
  analysisDeleteConfirmation: 'Are you sure you want to delete the Analysis?',
  analysisDeleteSuccess: 'Analysis deleted successfully!',
  analysisCancelSuccess: 'Analysis cancelled successfully!',
  analysisDeleteFailed: 'Analysis deletion failed. Try again later!',
  datasetDeleteConfirmation: 'Are you sure you want to delete the Dataset?',
  datasetDeleteSuccess: 'Dataset deleted successfully!',
  datasetDeleteFailed: 'Dataset deletion failed. Try again later!',
  questionnaireDeleteConfirmation:
    'Are you sure you want to delete the Questionnaire?',
  questionnaireDeleteSuccess: 'Questionnaire deleted successfully!',
  questionnaireDeleteFailed: 'Questionnaire deletion failed. Try again later!',
  addQuestionnaire: 'Add New Questionnaire',
  addQuestion: 'Add question',
  questionnaireTitlePlaceholder: 'Enter questionnaire title',
  questionnaireQuestionPlaceholder: 'Enter question',
  questionnaireCreationSuccess: 'Questionnaire created successfully.',
  questionnaireUpdatedSuccess: 'Questionnaire updated successfully.',
  questionnaireCreationFailure: 'Questionnaire creation failed.',
  questionnaireUpdateFailure: 'Questionnaire update failed.',
  questionUpdatedSuccess: 'Questionnaire updated successfully.',
  datasetCorrupted: 'Source file deleted',
  selectItemsToImport: 'Select items to import',
  importFilesDisclaimer: 'You can select 10 items',
  maximumDatasetSize: 'Maximum 10 files allowed in a dataset',
  minimumDatasetSize: 'Atleast 1 file is required to create a dataset',
  questionnaireBlocked: 'Questionnaire can not be edited due to association.',
  analysisFailed: 'Document Analysis Failed',
  analysisResourceFailure: "Error! Dataset resource(s) doesn't exist",
  analysisLimit:
    'Running Analyses limit reached! Please wait since another analysis is in-progress.',
  analysisEditingBlocked:
    'Analysis editing blocked. Please wait while in-progress analysis is completed.',
  closeAnalysisTitle: 'Cancel response/ Run in background',
  cancelAnalysis: 'Cancel Analysis',
  runInBackground: 'Run in background',
  continueAnalysis: 'Continue Analysis',
  newDataset: 'New Dataset',
  newQuestionnaire: 'New Questionnaire',
  analysisDescription:
    'Analyze document datasets with purpose-specific questionnaires.',
  datasetDescription: 'Create Datasets for documents to be analyzed',
  questionnaireDescription:
    'Create list of questions to analyze your documents against',
  inProgressAnalysis: 'Analysis in progress',
  inProgressAnalysisDescription:
    'Do you want to cancel the analysis or Run in background ?',

  regeneratingResponse: 'Regenerating response',
  updateQuestionnaire: 'Update Questionnaire',
  updateAssociatedQuestionnaireDescription:
    'Questionnaire with previous association can not be updated.',
  newQuestionnaireDescription: 'Save a new copy of questionnaire.',
  saveNew: 'Save New',
  analysisQuestionnaireUpdateLimit:
    'Questionnaire associated with other Analyses can not be updated.',
  newAnalysisTooltip: 'Analysis is already in-progress',
  analysisDetail: 'Analysis Detail',
  selectDataset: 'Select Dataset',
  selectQuestionnaire: 'Select Questionnaire',
  docAnalystDuplicationError: 'An item with the same title already exists.',
  generating: 'Generating',
  edited: 'Edited',
  updateQuestionnairePending: 'Update Questionnaire Pending',
  updateQuestionnairePendingDescription:
    'Please update questionnaire before exiting.',
  dateCreated: 'Date Created',
  clearAll: 'Clear All',
  questionnaireAssociationMsg: ` You can't edit the questionnaire due to its association with analysis.`,
  questionnaireName: 'Questionnaire Name',
  enterQuestionnaireName: 'Enter Questionnaire Name',
  updateQuestionnaireDescription:
    'Questionnaire is Updated. Click ‘Update’ to save changes.',
  updateQuestionnaireDialogTitle: 'Save Changes in Questionnaire',
  createdOn: 'Created On',
  datasetDeletedTooltip:
    'Original Dataset has been deleted / modified by owner.',
  mainFeatures: 'Main Features',
  docAnalystFeatures:
    'Extract precise answers from Enterprise documents$$Create purpose-specific Questionnaires$$Link knowledge Datasets effortlessly$$Run thorough Analyses$$Modify questions and Regenerate analysis on-the-go$$Active collaboration with Team Members',
  docClassifierFeatures:
    'Analyses text data from a wide range of enterprise documents to identify key themes, topics, or categories$$Classifies and sorts documents into user-defined categories without manual intervention$$Efficiently handles large volumes of documents, ensuring consistent classification',
  docSummaryGenerator:
    'Generates concise summaries from lengthy documents$$Identifies and highlights the most important information$$Allows users to adjust the length of the summary according to their needs$$Summarizes multiple documents simultaneously for comparison or overview$$Streamlines content review and enhances understanding$$Supports summarization in multiple languages, catering to diverse documents',
  model: 'Model',
  fromDate: 'From Date',
  sendQuestionnaire: 'Send Questionnaire',
  sentTo: 'Sent To',
  questionnaireSendSuccess: 'Questionnaire sent successfully',
  receivedFrom: 'Received From',
  questionnaireNotSharedYet: 'Not shared with anyone yet.',
  sentHistory: 'Sent History',
  byMe: 'By Me',
  all: 'All',
  filesDeletedInDatasetDisclaimer: 'Some files have been deleted.',
  allFilesDeletedInDatasetDisclaimer:
    'All files in the dataset have been deleted.',
  fileName: 'File Name',
  received: 'Received',

  // Document Analysis statuses
  L_DA_CM: 'Completed',
  L_DA_IP: 'Inprogress',
  L_DA_CN: 'Cancelled',
  L_DA_FL: 'Failed',
  L_DA_PC: 'Partially Completed',
  L_DA_RGR: 'Regenerating',

  // Assistant Documentation
  assistants: 'Assistants',
  allAssistant: 'All',
  createdByMe: 'Created By Me',
  creator: 'Creator',
  createAssistant: 'Create Assistant',
  updateAssistant: 'Update Assistant',
  assistantName: 'Assistant Name',
  assistantDescription: 'Assistant Description',
  assistantInstructions: 'Assistant Instructions',
  assistantModal: 'Assistant Modal',
  assistantCreatedSuccessfully: 'Assistant Created Successfully',
  assistantUpdatedSuccessfully: 'Assistant Updated Successfully',
  assistantDeletedSuccessfully: 'Assistant Deleted Successfully',
  iconUploadedSuccessfully: 'Icon Uploaded Successfully',
  namePlaceholder: 'Name your assistant',
  descriptionPlaceholder: 'Add a short description about this assistant. ',
  instructions: 'Instructions',
  customInstructions: 'Custom Instructions',
  instructionsPlaceholder:
    'What does this assistant do? How should it behave? What should it avoid doing?',
  conversationStarters: 'Conversation Starters',
  conversationStartersErrorMessage: 'Conversation starters should not be empty',
  conversationStartersPlaceholder:
    'Example prompts for the user to start the conversation.',
  aiModels: 'AI Models',
  aiModelsPlaceholder: 'Select any AI model',
  aiModelErrorMessage: 'Please select an AI Model',
  aiModelDisabledTooltip: 'Model does not support images as attachments.',
  knowledgeAddFiles: 'Enterprise Knowledge (Add Files)',
  addFiles: 'Add Files',
  knowledgeAddFilesAdded: '{{totalFiles}}/50 Files Added',
  enterpriseKnowledge: 'Enterprise Knowledge',
  worldKnowledgeWithWebSearch: 'World Knowledge with Web Search',
  conversationStartersCount: '{{totalCon}}/6 Added',
  preview: 'Preview',
  chooseEmojis: 'Select Image',
  keepItPrivate: 'Keep it Private',
  emojiDisclaimer: 'To Upload an Emoji it should be less than 500Kbs',
  assistantFilesPermission: 'You don’t have access to the following files',
  assistantNoFiles: 'Assistant’s enterprise knowledge files have been deleted',
  assistantPreviewNoFiles: `Please add files to the assistant's enterprise knowledge base to start a chat with it.`,
  noFilesDisclaimer: `Files added to the Assistant's knowledge have been deleted from the workspaces. Assistant controllers can add new files or change the Assistant’s capabilities to start a new chat with it.`,
  addFileDisclaimer:
    'Ensure files added in knowledge are accessible to users it is shared with',
  createUpdateConfirmationDialogTitle: 'Unsaved Changes',
  stayOnThisPage: 'Stay on this page',
  leaveWithoutSaving: 'Leave without saving',
  errorConfirmationDialog: 'Please resolve below errors before continuing',
  createUpdateConfirmationDisclaimer:
    'You are about to leave this page with unsaved changes.',
  assistantDeletedDisclaimer:
    'You cannot continue chatting with a deleted assistant.',
  outdatedVersionText: `You cannot continue chatting with an outdated version of this assistant. Start `,
  revokedVersionText: `Access on assistant’s knowledge files revoked. You cannot continue chatting. Click `,
  revokedAssistantPermission: `Access on assistant’s revoked. You cannot continue chatting. `,
  customizeAssistant:
    'Customize AI model responses for your specific use cases',
  newChat: 'new chat',
  here: 'here',
  revokedAssistant: ` to view the files.`,
  updatedAssistant: ` with the updated assistant.`,
  assistantUniqueViolationError: 'Assistant with the same name already exists',
  noAiModelDisclaimer: `The assistant's AI model has been disabled by the admin. Assistant controllers can either assign it a new model or request admin access to the disabled model to start a new chat.`,
  noAiModelTitle: 'Configured AI model disabled',
  addImage: 'Add Image',
  changeImage: 'Change Image',
  close: 'Close',
  assistantDeleted: 'Assistant Deleted',
  conversationStarterErrorMessage:
    'Assistant Starter cannot be longer than 300 characters',
  viewMore: 'View More',
  viewLess: 'View Less',
  assistantSelfAssignmentError:
    'Self Assignment/Unassignment on a resource is not allowed!',
  signup: 'Sign up',
  signupInfo:
    'Get instant, AI-powered access to critical info, helping teams boost productivity. ',
  signupInfo2: 'Get your 7-days free trial now!',
  emailNotValid: 'Please enter a valid email address',
  emailRequired: 'Email is Required',
  sendInvite: 'Send Invite',
  login: 'Log in',
  alreadyHaveAccount: 'Already have an account?',
  signupConfirmation1: 'An invite has been sent to your email address',
  signupConfirmation2:
    ' Click on “Click to Proceed” button in the received email to setup up your account. The invitation link will expire after 2 days.',
  workEmail: 'Work Email',
  getInvite: 'Get Invite',
  chatUnavailable: 'Chat Unavailable',
  shareUsingLink: 'Share using link',
  roleDescription: 'Role for EmpowerGPT users with the link',
  errorGeneratingLink: 'Error generating link',

  // Confluence
  confluenceModalTitle: 'Import content from Confluence',
  confluenceModalSubtitle:
    'Navigate within your Confluence, and select required content for importing.',
  confluenceMySpaces: 'My Spaces',
  confluenceSharedSpaces: 'Shared Spaces',
  openInConfluence: 'Open in Confluence',
  confluenceResourceProviderWelcomeTitle:
    'Steps to Configure Importing from Confluence',
  confluenceWelcomeInstruction1:
    'Atlassian organization admin’s account details.',
  confluenceWelcomeInstruction2:
    'Create and configure an OAuth 2.0 App in Atlassian Developer.',
  confluenceWelcomeInstruction3:
    'Enter details against the created application.',
  confluenceWelcomeInstruction4:
    'Add required permissions to the created application’s API scope.',
  confluenceConfigurationSuccessTitle: 'Importing from Confluence Configured',
  confluenceConfigurationSuccessDescription1:
    'Content can now be imported from Confluence in EmpowerGPT as collections.',
  confluenceConfigurationSuccessDescription2:
    "Imported content's visibility will be based on its permissions at Confluence.",
  confluenceAdminConfigurationStep1Start: 'In a new tab, navigate to the ',
  atlassianManagementText: 'Atlassian account’s API token management page.',
  confluenceAdminConfigurationStep1End:
    ' Make sure to sign in with Atlassian’s organization admin account.',
  confluenceAdminConfigurationStep2:
    'Click on the “Create API token” button, and enter a suitable name such as “EmpowerGPT - [Company Name]”.',
  confluenceAdminConfigurationStep3:
    'After clicking on create, you shall be able to copy the API token. Paste the API token, along with the email address of the signed in organization admin.',
  apiTokenRequired: 'API token is required',
  apiToken: 'API TOKEN',
  confluenceAdminConfigurationStep4:
    'Paste the base URL for your organization’s Atlassian applications below. (For example, if your organization name is Contoso, the base URL for your organization’s Atlassian applications will be https://contoso.atlassian.net/wiki)',
  invalidConfluenceUrl: 'Invalid Confluence Base URL provided.',
  confluenceStep1Title: 'Atlassian organization admin’s account details.',
  atlassianBaseUrl: 'Atlassian base URL',
  confluenceStep2Title:
    'Create and configure an OAuth 2.0 App in Atlassian Developer.',
  confluenceStep2Instruction1Start: 'In a new tab, sign in to ',
  confluenceStep2Instruction1End: 'Atlassian Developer.',
  confluenceStep2Instruction2:
    'Once signed in, click on the “User Menu” on top right to select the “Developer Console” button from the drop-down. ',
  confluenceStep2Instruction3:
    'Click on the “Create” button under “My apps” section and select “OAuth 2.0 integration” from the drop-down.',
  confluenceStep2Instruction4:
    'Enter a suitable name for the application such as “EmpowerGPT - [Company Name]”. Check the box below to agree to Atlassian’s developer terms, and click on “Create”. ',
  confluenceStep2Instruction5:
    'You will land to the application’s “Overview” page after the application is successfully created. Navigate to the “Authorization” page, and click on the “Add” button against “OAuth 2.0 (3LO)” to enable user authorization from Atlassian.',
  confluenceStep2Instruction6:
    '6. Paste the following URL in the “Callback URL” field and click on “Save changes”.',
  callBackUrl: 'Callback URL',
  confluenceStep2Instruction7:
    'Now navigate to the “Distribution” page and click on “Edit” in front of the “Distribution controls” section. Change the distribution status to “Sharing”.',
  confluenceStep2Instruction8:
    'Add your company name as the vendor name and paste the following link under the privacy policy field.',
  privacyPolicy: 'Privacy Policy',
  privacyPolicyRequired: 'Privacy Policy is required',
  confluenceStep2Instruction9:
    'Select “No” against the “does your app store personal data?” drop down and click on save changes button at the end.',
  appCreationStepsPerformed:
    'All of the above steps have been performed in Atlassian Developer.',
  confluenceStep3Title: 'Enter details against the created application.',
  confluenceStep3Instruction1: 'Navigate to the “Settings” page. ',
  confluenceStep3Instruction2:
    'Copy the “Client ID” and “Secret ID” and enter each in the respective field below.',
  clientId: 'Client ID',
  clientSecret: 'Secret',
  confluenceStep4Title:
    'Add required permissions to the created application’s API scope.',
  confluenceStep4Instruction1:
    'Navigate to the “Permissions” page and click on the “Add” button against the “Confluence API” row. ”Add” button should update to “Configure”.',
  confluenceStep4Instruction2:
    'Click on the “Configure” button, and click on “Edit Scopes” under the “Classic scopes” tab',
  confluenceStep4Instruction3:
    'Select the following permissions by check the box under the “Select” column.',
  confluenceStep4Instruction3a: 'Read Confluence space summary.',
  confluenceStep4Instruction3b: 'Read Confluence content properties',
  confluenceStep4Instruction4:
    'The above steps have been performed on the created application in Atlassian Developer.',
  resourceProviderConfluenceEnabledSuccessfully:
    'Confluence enabled successfully.',
  resourceProviderConfluenceDisabledSuccessfully:
    'Confluence disabled successfully.',
  errorUpdatingConfluence: 'Error updating Confluence',
  resourceProviderConfluenceCreationFailed: 'Error enabling Confluence.',
  viewDetails: 'View Details',
  authWindowClosed: 'Authentication window was closed.',
  tokenExchangeFailed: 'Token exchange failed',
  failedRefreshToken: 'Failed to refresh access token',
  errorDecodingToken: 'Error decoding token: ',
  noWhitespaceAllowed: 'Please remove white spaces',

  // Settings
  systemSettings: 'System Settings',
  security: 'Security',
  enableForNewChats: 'Enable for new chats',
  aboutYou: 'About You',
  aboutYouSubtitle:
    'This information will be used to personalise your experience',
  aboutYouLabel:
    'Tell EmpowerGPT about yourself to get more personalized responses.',
  companyName: 'Company Name',
  department: 'Department',
  jobRole: 'Job Role',
  country: 'Country',
  deleteAccount: 'Delete Account',
  deleteAccountInfo:
    'Permanently delete your entire account, documents upload in My Workspace, and your chats. This is irreversible.',
  aboutYouPlaceholder:
    '- What do you primarily do at work?\n- What are your key responsibilities?',
  giveInstructionsLabel: 'How would you like EmpowerGPT to respond?',
  giveInstructionsPlaceholder:
    '- What tone should EmpowerGPT use: formal or casual?\n- Preferred length of EmpowerGPT responses: short or detailed?',
  questionsAsked: 'Questions asked',
  documentsUploaded: 'Documents uploaded',
  assistantsCreated: 'Assistants Created',
  changePasswordLabel:
    "Your password is the key to your account. Ensure it's strong and secure.",
  settingsSavedSuccessfully: 'Settings saved successfully',
  profileUpdatedSuccessfully: 'Profile updated successfully',
  uploadingOrProcessingText: '{{status}} attachments',
  failedOrUnsupportedText:
    'Error {{status}} attachments. Remove attachment(s) with an error to send message.'
};
